import React from 'react';
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Card,
  CardContent,
  CardActionArea, Stack,
} from "@mui/material";
import ReactGA from "react-ga4";

interface ProjectsProps {
  // Define your props here
}

const Projects: React.FC<ProjectsProps> = (props) => {
  const theme = useTheme();
  const largeView = useMediaQuery(theme.breakpoints.up('md'));

  const flexDirection = largeView ? "row" : "column";
  // const cardHeight = largeView ? 'auto' : undefined;

  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
      <Typography variant="h4" gutterBottom>
        Projects
      </Typography>
      <Box sx={{ p: 2, display: 'flex', gap: '30px', flexDirection, alignItems: 'stretch' }}>
        <Card sx={{ maxWidth: 400, flex: '1 1 100%', overflow: 'visible' }}>
          <CardActionArea href="https://discord.com/oauth2/authorize?client_id=1278729036528619633" target="_blank"
                          onClick={(e) => ReactGA.event(`click_link_vrccomingsoon`)}
                          sx={{height: '100%'}}>
            <CardContent>
              <Stack direction="column">
                <Stack direction="row" sx={{pb: 1}}>
                  <Box>
                    <img src="/img/meeting_notes_icon.png" width={80} height={80} alt="Meeting Notes Icon" />
                  </Box>
                  <Typography variant="h6" sx={{ pl: 1, textAlign: 'center', alignSelf: 'center', flex: '1 1 auto' }}>Meeting Notes Discord Bot</Typography>
                </Stack>
                <Typography variant="body2">
                  A discord bot for recording meetings, providing an audio recording, chat log, attendance list, and audio transcription.
                </Typography>
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ maxWidth: 400, flex: '1 1 100%', overflow: 'visible' }}>
          <CardActionArea href="https://ttrpgscheduler.com" target="_blank"
                          onClick={(e) => ReactGA.event(`click_link_ttrpgscheduler`)}
                          sx={{height: '100%'}}>
            <CardContent>
              <Typography variant="h6" sx={{ pb: 2 }}>TTRPG Scheduler</Typography>
              <Typography variant="body2">
                An easy-to-use tool for scheduling weekly recurring events with friends
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ maxWidth: 400, flex: '1 1 100%', overflow: 'visible' }}>
          <CardActionArea href="https://mods.vintagestory.at/show/mod/2999" target="_blank"
                          onClick={(e) => ReactGA.event(`click_link_vsthebasics`)}
                          sx={{height: '100%'}}>
            <CardContent>
              <Typography variant="h6" sx={{ pb: 2 }}>"The BASICs" Vintage Story Mod</Typography>
              <Typography variant="body2">
                A proximity chat mod that pioneered role-playing communities within the game Vintage Story
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ maxWidth: 400, flex: '1 1 100%', overflow: 'visible' }}>
          <CardActionArea href="https://foundryvtt.com/packages/shadowrun5e" target="_blank"
                          onClick={(e) => ReactGA.event(`click_link_shadowrunfoundry`)}
                          sx={{height: '100%'}}>
            <CardContent>
              <Typography variant="h6" sx={{ pb: 2 }}>Shadowrun5E for Foundry VTT</Typography>
              <Typography variant="body2">
                A Foundry VTT System that greatly simplifies running the Shadowrun TTRPG
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ maxWidth: 400, flex: '1 1 100%', overflow: 'visible' }}>
          <CardActionArea href="https://ddmers.com/console" target="_blank"
                          onClick={(e) => ReactGA.event(`click_link_consolegame`)}
                          sx={{height: '100%'}}>
            <CardContent>
              <Typography variant="h6" sx={{ pb: 2 }}>Babby's First Console</Typography>
              <Typography variant="body2">
                A small WIP web game intended to introduce newbies to terminal/console concepts
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ maxWidth: 400, flex: '1 1 100%', overflow: 'visible' }}>
          <CardActionArea href="https://discord.gg/PYKTaJ5Ett" target="_blank"
                          onClick={(e) => ReactGA.event(`click_link_vrccomingsoon`)}
                          sx={{height: '100%'}}>
            <CardContent>
              <Typography variant="h6">VRC Avatars and Assets</Typography>
              <Typography variant="subtitle2" sx={{ pb: 1 }}>Coming soon...</Typography>
              <Typography variant="body2">
                Join my discord, follow my twitter, or subscribe for updates on Gumroad!
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        {/*<Card sx={{ maxWidth: 400, flex: '0 0 100%' }}>*/}
        {/*  <CardActionArea sx={{height: '100%'}} href="https://ddmers.com/" target="_blank"*/}
        {/*                  onClick={(e) => ReactGA.event(`click_link_ddmers`)}>*/}
        {/*    <CardContent>*/}
        {/*      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', pb: 2}}>*/}
        {/*        <MenuIcon/>*/}
        {/*        <Typography variant="h6" sx={{pl: 1}}>DDM</Typography>*/}
        {/*      </Box>*/}
        {/*      <Typography variant="body2">*/}
        {/*        My community of edgy friends lol*/}
        {/*      </Typography>*/}
        {/*    </CardContent>*/}
        {/*  </CardActionArea>*/}
        {/*</Card>*/}
      </Box>
    </Box>
  );
};

export default Projects;