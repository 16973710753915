import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { DevSupport } from "@react-buddy/ide-toolbox";
import { ComponentPreviews, useInitial } from "./dev";
import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import roboto300 from "@fontsource/roboto/files/roboto-latin-300-normal.woff2"
import roboto500 from "@fontsource/roboto/files/roboto-latin-500-normal.woff2"

import ReactGA from "react-ga4";

ReactGA.initialize("G-6ERE34TX88");


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Helmet>
      <link ref="preload" as="font" href={roboto300} />
      <link ref="font" href={roboto300} />
      <link ref="preload" as="font" href={roboto500} />
      <link ref="font" href={roboto500} />
    </Helmet>
    <DevSupport ComponentPreviews={ComponentPreviews}
                useInitialHook={useInitial}
    >
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </DevSupport>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
