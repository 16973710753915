import { IconButton } from "@mui/material";
import React from "react";
import ReactGA from "react-ga4";

const SocialsIconButton: React.FC<React.PropsWithChildren<{
  href: string;
  name: string;
}>> = ({ href, children, name }) => {
  return (<IconButton
    href={href}
    target="_blank"
    sx={{
      ":hover": {
        transform: "scale(1.15)",
      },
      "transition": "transform 0.3s ease-in-out",
    }}
    size="large"
    onClick={(e) => ReactGA.event(`click_link_${name}`)}>
    {children}
  </IconButton>);
}

export default SocialsIconButton;