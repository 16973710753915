import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const RefHandler: React.FC<{
  type: "vrc" | "discord" | "",
}> = ({
  type
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.event(`from_${type}`);
    navigate("/");
  }, [type, navigate]);

  return (<div />);
}

export default RefHandler;