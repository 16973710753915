import React from "react";
import { Box, Grid } from "@mui/material";
import Footer from "./Footer";

const PageContainer: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid display="flex" direction="column" height="100vh" width="100vw" sx={{ overflowX: "hidden" }}>
        <Box sx={{
          pt: 5,
          pl: 4,
          pr: 4,
          pb: 2,
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          flexDirection: 'column',
          maxWidth: "100vw",
          flexGrow: 1,
          // gap: 4,
        }}>
          {children}
        </Box>
        <Footer/>
      </Grid>
    </Box>
  );
};

export default PageContainer;
