import React from "react";
import Projects from "./Projects";
import About from "./About";
import { Divider } from "@mui/material";

const Home: React.FC = () => {
  return (<>
    <About />
    <Divider flexItem sx={{my: 4}}/>
    <Projects />
    </>);
}

export default Home;